import React from "react";
import styles from "./styles.module.scss";
import theme from "../../Styles/theme.scss";
import classNames from "classnames";
// Components
import Backdrop from "../Backdrop";
import { CloseIcon } from "../../Styles/Icons";
import { HelpIcon } from "../../Styles/Icons/DesignSystem";

const NewStyleModal = ({
  title,
  context,
  showAnimation,
  hide,
  children,
  contextClass,
  showCloseIcon,
  helpIcon,
  size,
}) => {
  return (
    <div className={`${styles.container}`}>
      <Backdrop show={true} onClick={hide} />
      <div
        className={classNames(
          size === "large"
            ? styles.large_modal
            : size === "medium"
              ? styles.medium_modal
              : styles.modal,
          // Must be expressed in this way, otherwise a modal that does not have the showAnimation property will default to null, which is falsy, and will incorreectly apply a fade_out style
          showAnimation === false ? styles.fade_out : styles.fade_in
        )}
      >
        <div className={styles.modal_top}>
          {helpIcon && (
            <div className={styles.icon}>
              <HelpIcon colour={theme.neutral7} width={31} />
            </div>
          )}
          {title && <div className={styles.modal_title}>{title}</div>}
        </div>
        {context && (
          <div
            className={`${styles.modal_context} ${
              contextClass ? contextClass : undefined
            }`}
          >
            {context}
          </div>
        )}
        {showCloseIcon && (
          <CloseIcon className={styles.modal_close} onClick={hide} />
        )}
        <div className={styles.modal_body}>{children}</div>
      </div>
    </div>
  );
};

export default NewStyleModal;
