import styles from "./styles.module.scss";

const SensorsHeader = ({ isHub, loading }) => {
  const title = isHub ? "Connect the Lilli hub" : "Connect the sensors";
  const hubSubtitle =
    "To begin the installation, you must turn on the Lilli hub.";
  const hubIsConnecting =
    "Please wait until all three green lights are on. This can take up to 5 minutes.";

  const hubInstructions = [
    {
      text: "1. Plug the hub into a power outlet.",
    },
    { text: "2. Click 'Connect me'" },
  ];

  return (
    <div>
      <div className={styles.title}>
        {loading && isHub ? "Connecting..." : title}
      </div>
      {isHub && (
        <div className={styles.subtitle}>
          {loading ? hubIsConnecting : hubSubtitle}
        </div>
      )}
      {isHub &&
        !loading &&
        hubInstructions?.map((instruction, index) => {
          if (instruction?.image) {
            return (
              <div style={{ marginBottom: "14px" }}>
                <img
                  key={index}
                  src={instruction.image.image}
                  alt={instruction.image.alt}
                  style={{
                    maxHeight: `${instruction?.image.height}px`,
                    borderRadius: "8px",
                  }}
                />
              </div>
            );
          }
          if (instruction?.text) {
            return (
              <div key={index} className={styles.subtitle}>
                {instruction.text}
              </div>
            );
          }
          return null;
        })}
      {isHub && (
        <div className={styles.subtitle}>
          Do not close the app while you complete this step.
        </div>
      )}
    </div>
  );
};

export default SensorsHeader;
