export const Starting_Instructions = [
  "1. Plug in the smart plug first. This helps the other sensors connect faster.",
  "2. Insert batteries into the remaining sensors.",
  "3. Only when all batteries are in should you click 'Test connection'.",
];

export const Locations_Required = [
  "All the sensors are online.",
  "Next, select the location for each sensor below.",
];

const locationInstructions =
  "Once they are online, provide the location for each sensor.";
const doNotCloseApp = "Do not close the app while you complete this step.";

export const Not_All_Sensors_Online = [
  "Not all the sensors came online. Click 'Need help?' to troubleshoot.",
  `${locationInstructions} ${doNotCloseApp}`,
];
