import React from "react";
import styles from "./styles.module.scss";
import InstallationGoBackButton from "../../InstallationGoBackButton";

const TroubleshootingMethods = ({
  options,
  setHelpChoice,
  closeModal,
  helpChoice,
  isDesktop,
  sensorType,
}) => {
  const optionTitle = (sensorType, key) => {
    if (key === "Reset the sensor") {
      return `Reset the ${sensorType.toLowerCase()}`;
    } else {
      return key;
    }
  };

  return (
    <div>
      {options.map((option, index) => {
        if (sensorType === "Smart plug" && index === 0) {
          return null;
        } else {
          return (
            <div
              className={`${styles.option} ${
                helpChoice === option.key && styles.selected
              }`}
              key={index}
              onClick={() => setHelpChoice(option.key)}
            >
              <div>
                <div className={styles.option_title}>
                  {sensorType === "Smart plug" ? index : index + 1}.{" "}
                  {optionTitle(sensorType, option?.key)}
                </div>
                <div className={styles.option_body}>{option.value}</div>
              </div>
            </div>
          );
        }
      })}
      {!isDesktop && (
        <InstallationGoBackButton
          label="Go back"
          onClick={closeModal}
          marginTop="24px"
        />
      )}
    </div>
  );
};

export default TroubleshootingMethods;
