export const HUB_INSTRUCTIONS = [
  {
    text: "1. The biggest box in your package is the Lilli hub.",
  },
  {
    text: "2. Plug it into a central location in the home (see tips).",
  },
  {
    note: "You do not need the ethernet cable. You only need the power cable.",
  },
  {
    hint: [
      "Find a spot that isn't behind any large objects or in a cupboard, as this will make it harder to receive the signals from the sensor network.",
    ],
  },
];
